@import "base/typography";
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin top and bottom to avoid the navbar & footer */
    background-color: RGBA(255, 255, 241, 1.00);
    font-size: 1.0em;
    overflow-x: hidden;
    color: RGBA(18, 26, 32, 0.80);
}

.starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
}

main {
    margin-bottom: 5rem;
}

// give the navbar enough margin to pass below the into bar
.navbar {
    margin: 2rem 0;
}


/* change the background color */

.navbar-custom {
    background-color: #8B7748;
}


/* change the brand and text color */

.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: #121A20;
    font-size: 1.5rem;
    font-weight: 700;
}


/* change the link color */

.navbar-custom .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}


/* change the color of active or hovered links */

.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: #FFFFF1;
}

// Try to get the button on the same line
.navbar-toggler {
    padding: .25rem .50rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

// Make the brand smaller when on xs devices
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: #121A20;
    font-size: 1.5rem;
    @media (max-width: 320px) {
        font-size: 1.1rem;
    }
    ;
    @media (min-width: 321px) {
        font-size: 1.4rem;
    }
    ;
    @media (min-width: 410px) {
        font-size: 1.6rem;
    }
    ;
    @media (min-width: 1024px) {
        font-size: 2.0rem;
    }
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.0rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}


/* Top color bar styling
-------------------------------------------------- */

#intro {
    top: 0;
    position: absolute;
    background-color: #121A20;
    height: 2rem;
    width: 100%;
}


/* Sticky footer styles
-------------------------------------------------- */

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 5rem;
    line-height: 5rem;
    /* Vertically center the text there */
    background-color: #121A20;
}


/* Custom page CSS
-------------------------------------------------- */


/* Pullquote Section */

#pullquote.copy {
    background: #f7f7f7 url(images/pullquote.png) no-repeat 15px 15px;
    padding: 15px 20px 15px 45px;
    margin-bottom: 1.5em;
}

#pullquote .thepullquote {
    font-size: 1.5em;
    margin-bottom: 5px;
    line-height: 1.3em;
}

#pullquote .thecitation {
    color: #AAA;
    font-style: italic;
    padding-left: 15px;
}


/* Blockquote styling */

.blockquote {
    background: #f7f7f7 url(/images/pullquote.png) no-repeat 15px 15px;
    padding: 15px 20px 15px 45px;
    margin-bottom: 1.5em;
}

.blockquote .copy {
    font-size: 1.3em;
    margin-bottom: 5px;
    line-height: 1.1em;
}

.blockquote .citation {
    color: #AAA;
    font-style: italic;
    padding-left: 15px;
}

.white-box-side-book {
    color: #111c24;
    background-color: #ffffff;
    border: #c2b695 1px solid;
}

.side-elements {
    margin-bottom: 8rem;
}

.white-box-general {
    padding: 15px 10px 10px 10px;
    color: #111c24;
    background-color: #ffffff;
    border: #8B7748 1px solid;
}

.book-btn {
    background-color: #8B7748;
    color: #121a20;
    border: none;
}

.btn-primary:hover {
    color: #121a20;
    background-color: lighten(#8b7748, 20%);
}

a {
    color: #121A20;
}
.home-deck {
    margin-bottom: 6rem;
}